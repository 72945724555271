// import Colbdentistblog from "components/Blogs/Colbdentistblog";
// import Profdentistblog from "components/Blogs/Profdentistblog";
// import UploadFile from "components/FileUpload/UploadFile"; //Not used anymore
import React from "react";
import Finance from "components/Finance/Finance";
import Footer from "components/Footer/Footer";
import Loading from "components/Loading";
import MenuHeader from "components/MenuHeader/MenuHeader";
import Newpatient from "components/NewPatient/newpatient";
import NewAppointment from "components/Appointment/NewAppointment";
import OurServices from "components/OurServices/OurServices";
import Reviews from "components/Reviews/reviews";
import Alldocs from "components/TeamProfile/TeamProfileFull";
import AboutUs from "pages/AboutUs";
import LandingPage from "pages/Home";
import PrivacyPolicy from "pages/PrivacyPolicy";
import { lazy, Suspense, useEffect } from "react";
import ReactGA from "react-ga";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import ClinicSelection from "components/Blogs/ClinicSelection";
import Mouthwash from "components/Blogs/Mouthwash";
import Periodontal from "components/Blogs/Periodontal";
import PreventiveDentalCare from "components/Blogs/PreventiveDentalCare";
import Toothbrush from "components/Blogs/Toothbrush";
import ToothDecay from "components/Blogs/ToothDecay";
import BlogsIndex from "pages/BlogsIndex";
import NotFound from "components/NotFound/NotFound";

// import Vassist from "components/Vassist";

function App() {
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1";
  //   script.async = true;
  //   document.body.appendChild(script);
  //   return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);
  // console.log(process.env.REACT_APP_BASE_URL);


  const ClinicLocationReg = lazy(() => import("components/Locateus/LocateUs"));

  useEffect(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV !== "development") {
      const TRACKING_ID = process.env.REACT_APP_ANALYTICS_TRACKING_ID_SELF;
      //********************************* */
      // Open the below for PRODUCTION
      ReactGA.initialize(TRACKING_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
      //******************** */

      //GOOGLE ANALYTICS
      // ReactGA.initialize(
      //   [ {
      //     trackingId: process.env.REACT_APP_ANALYTICS_TRACKING_ID_SELF,
      //     gaOptions: { name: "CDC" },
      //   },
      //     {
      //       trackingId: process.env.REACT_APP_ANALYTICS_TRACKING_ID_LIGHTSPEEDWEB,
      //       gaOptions: { name: "LSW" },
      //     },

      //   ],
      //   { debug: false, alwaysSendToDefaultTracker: false }
      // );

      // ReactGA.pageview(window.location.pathname + window.location.search, [
      //   "CDC",
      //   "LSW",

      // ]);
    }
  }, []);

  return (
    <>
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <MenuHeader />

          <Routes>
            {/* <Route path="/appointment" element={<NewAppointment />} /> */}

            <Route path="/" element={<LandingPage />} />
            <Route path="/home" element={<LandingPage />} />

            <Route path="/services" element={<OurServices />} />
            <Route path="/meetdocs" element={<Alldocs />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/finance" element={<Finance />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/locateus" element={<ClinicLocationReg />} />
            <Route path="/newpatient" element={<Newpatient />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />

            {/* BLOG */}
            <Route path="/blog" element={<BlogsIndex />} />
            <Route path="/cs" element={<ClinicSelection />} />
            <Route path="/mw" element={<Mouthwash />} />
            <Route path="/pd" element={<Periodontal />} />
            <Route path="/pdc" element={<PreventiveDentalCare />} />
            <Route path="/tb" element={<Toothbrush />} />
            <Route path="/tdc" element={<ToothDecay />} />
            <Route path="*" element={<NotFound />} />
            <Route render={() => <Navigate to="/" />} />
          </Routes>
          {/* <Vassist /> */}
          <Footer />
        </BrowserRouter>
      </Suspense>
    </>
  );
}

export default App;
