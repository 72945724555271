import React, { lazy, Suspense, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Contact from "components/Contact/Contact";
import Loading from "components/Loading";
import ScrollButton from "components/ScrollTop/ScrollButton";
import HeroLandingPage from "./HeroLandingPage";
import { HOME } from "assets/helmet";
import PromotionMonthly from "./PromotionMonthly";
import { HelmetTag } from "components/HelmetTag";


const ServicesIntroHome = lazy(() => import("components/OurServices/ServicesIntro"));
const WhyusHome = lazy(() => import("components/WhyUs/Whyus"));
const ReviewHome = lazy(() => import("components/Reviews/reviews"));
const HygienistHome = lazy(() => import("./Hygienist"));
const AffHome = lazy(() => import("components/Affiliations/Aff"));

const Home = () => {
  const [ isPopupOpen, setIsPopupOpen ] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const dynamicContent = "This is dynamic content inside the popup!";

  return (
    <>

      <HelmetTag title={HOME.title} desc={HOME.description} />
      <div id="page" className="page">
        <div className="hero-sectionx">
          <HeroLandingPage />
        </div>
        <Container>
          <Suspense fallback={<Loading />}>
            <ServicesIntroHome />
            <PromotionMonthly />
            <WhyusHome />
            <ReviewHome />
            <HygienistHome />
            <div id="contactus">
              <Contact />
            </div>
            <AffHome />
          </Suspense>
        </Container>
      </div>
      <ScrollButton />
    </>
  );
};

export default Home;
