import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

const Footer = () => {
  const fb = "https://www.facebook.com";
  const relativeOrAbsoluteURL1 =
    "https://www.facebook.com/colbornefamilydental1";
  const fbUrl = new URL(relativeOrAbsoluteURL1, fb).href;
  const insta = "https://www.instagram.com/colbornedental/";

  const relativeOrAbsoluteURL2 = "https://www.instagram.com/colbornedental/";
  const instaUrl = new URL(relativeOrAbsoluteURL2, insta).href;

  return (
    <>
      <footer id="footer-1" className="bg-image    footer division">
        <div className="container p-10">
          <div className="row mt-10">
            <div className="col-md-6 col-lg-3">
              <div className="footer-info mb-10">
                {/* For Retina Ready displays take a image with double the amount of pixels that your image will be displayed (e.g 360 x 80  pixels) */}
                {/* <img src={require('../../images/footer-logo-white.png')} width="180" height="40" alt="footer-logo"/> */}
                <p className="p-sm ">Our Social Media Connect</p>
                <div className="footer-socials-links ">
                  <ul className="foo-socials text-center clearfix">
                    <li>
                      <a
                        href={fbUrl}
                        aria-label="Facebook"
                        rel="noopener noreferrer"
                        target="_blank"
                        className="ico-facebook"
                      >
                        <i className="bi bi-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href={instaUrl}
                        aria-label="Instagram"
                        rel="noopener noreferrer"
                        target="_blank"
                        className="ico-instagram"
                      >
                        <i className="bi bi-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="footer-box mb-10">
                <span className="footerheader sm">Our Location</span>
                <p>10 Victoria Square</p>
                <p>Colborne, ON K0K 1S0</p>
                <p className="foo-email mt-20">
                  Email:{" "}
                  <a href="mailto:colbornefamilydental@gmail.com">
                    colbornefamilydental@gmail.com
                    {/* colbornefamilydental@gmail.com */}
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="footer-box mb-10">
                <span className="footerheader sm">Working Time</span>
                <p className="p-sm">
                  <span className="text-gray-400"> Mon - Fri - </span>{" "}
                  <span className="text-center font-semibold text-yellow-50">
                    8:30 AM - 5:00 PM
                  </span>
                </p>{" "}
                <p className="p-sm">
                  <span> Sat -</span>{" "}
                  <span className="text-center text-yellow-50">
                    8:30 AM - 3:00 PM
                  </span>
                </p>{" "}
                <p className="p-sm">
                  <span> Sun -</span>{" "}
                  <span className="text-center font-semibold text-yellow-50">
                    Closed
                  </span>
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="footer-box mb-10 row space-y-2">
                <span className="footerheader sm col-sm-12">
                  Dental Emergency
                </span>

                <a href="tel:905-355-1419" className="callusbtn col-sm-12">
                  <span className="footerheader   font-semibold ">
                    <i className="bi bi-telephone text-light text-yellow-50 mr-2"></i>
                    905-355-1419
                  </span>
                </a>
                <Link to="/privacypolicy">
                  <span className="footerheaderx text-white sm">
                    Privacy Policy
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="bottom-footerx">
              <div className="row">
                <div className="col-md-6">
                  <p className="footer-copyright">
                    &copy; {new Date()?.getFullYear() || 2025} <span>COLBORNE FAMILY DENTAL</span>. All Rights
                    Reserved
                  </p>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="bottom-footer">
            <div className="text-center text-white">
              <p className="text-sm ">
                {" "}
                Designed and Developed by{" "}
                <a
                  href="mailto:intellisense@outlook.com"
                  className="text-white"
                >
                  intellisense@outlook.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>{" "}
    </>
  );
};

export default Footer;
