import ScrollButton from "components/ScrollTop/ScrollButton";
import React from "react";
import { Container, Image } from "react-bootstrap";
import "./styles.css";

function LocateUs() {
    return (
        <>
            <Container>
                <section className="section-title mt-4">
                    <h3 className="text-center titleSubHeader h3-md steelblue-color">Locate Us</h3>

                    <div className="row float-leftx  mt-2">

                        {/* <GetDirectionsBox name="mob"
                        btncss={"btn text-center mt-2 btn-sm bg-blue blue-hover"}
                        iconcss={"text-white space-x-2"}
                    /> */}


                        {/* <GetDirectionsBox
                        btncss={"btn btnsm   btn-sm bg-blue blue-hover"}
                        iconcss={"text-white"}
                    /> */}
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            {/* <Image src={clinic} rounded></Image> */}
                            <Image loading="lazy" className="shadow-lg imagedim mb-2 rounded" alt="Colborne Dental Clinic" src="imagesx/webp/ClinicfrontFacing.webp" rounded></Image>
                            <div className="contact-box mb-10 float-left text-justify mt-2">
                                <h2 className="h5-sm steelblue-color">Colborne Family Dental</h2>
                                <h3 className="text-muted">10 Victoria Square</h3>
                                <h3 className="text-muted">Colborne,ON K0K 1S0</h3>
                                <h3 className="text-muted">Canada</h3>
                                <h3>Phone: 1-(905)-355-1419</h3>
                                <h3>
                                    Email:{" "}
                                    <a
                                        href="mailto:colbornefamilydental@gmail.com"
                                        className="text-primary"
                                    >
                                        colbornefamilydental@gmail.com
                                    </a>
                                </h3>
                                <div>
                                    <br></br>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9 col-lg-9 col-sm-9">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2869.7659499932934!2d-77.89020568495964!3d44.005564079110826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d675b376a6c92f%3A0xbc9d5b9b0c319cf7!2sColborne%20Family%20Dental!5e0!3m2!1sen!2ssg!4v1652521659530!5m2!1sen!2ssg"
                                width="100%"
                                height="450"
                                border="0"
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                                title="Colborne Family Dental"
                            ></iframe>
                        </div>
                    </div>
                </section>

                <ScrollButton />
            </Container>
        </>

    );
}

export default React.memo(LocateUs);
