import React from "react";
import ContactFormNew from "./ContactFormNew";
import SideBar from "./SideBar"; //SendContactUsEnquiry

import "./styles.css";

const Contact = () => {
  return (
    <>
      {/* <HelmetTag title={CONTACTUS.title} desc={CONTACTUS.description} /> */}

      <div className="container md:mt-4">
        {/* <BreadCrumbCDC title="Contact us" /> */}
        {/* Start */}

        <div className="text-center" style={{marginBottom: "12px"}}>
          <span className="h3 titleSubHeadert ext-center steelblue-color">
            Have a Question? Get In Touch
          </span>
        </div>
        {/* Side Bar */}
        {/* <div className="flex flex-col justify-center gap-5 mt-4 sm:flex-row "> */}
        <div>
          <SideBar />
        </div>
      </div>
    </>
  );
};

export default Contact;
