import React from "react";
import { oservices } from "assets/data/ourservices";
import { SERVICE } from "assets/helmet";
import { HelmetTag } from "components/HelmetTag";
import Loading from "components/Loading";
import { Suspense, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as Services from "./ServiceItems";
import "./styles.css";

export default function ServicesDesktop() {
    const [ selectedService, setSelectedService ] = useState(null);
    const location = useLocation(undefined);

    const renderServiceItem = (name) => {
        if (name) {
            const ServiceSelected = Services[ name ];
            return <ServiceSelected />;
        }
    };
    useEffect(() => {
        if (location.state !== undefined && location.state !== null) {
            const { toShowItem } = location.state;

            setSelectedService(toShowItem);
        } else {
            setSelectedService("AmalgamFree");
        }
    }, []);

    return (
        <>
            <HelmetTag title={SERVICE.title} desc={SERVICE.description} />

            <div id="tabs-2" className="flex py-3 mb-25 tabs-section division">
                {/*	{ /* SECTION TITLE */}
                <div className="flex">
                    <aside className="w-2/6 " aria-label="Sidebar">
                        <h4 className="h4 steelblue-color">Services </h4>
                        <div className="text-sm text-gray-900 bg-white border border-gray-200 rounded-lg list-group font-mediumx dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                            <ul
                                className="overflow-y-scroll nav nav-pills h-80"
                                id="pills-tab"
                                role="tablist"
                            >
                                {oservices.map((x, key) => {
                                    return (
                                        <li key={key} className="text-left nav-item icon-xs">
                                            <button
                                                className={
                                                    ((key ? 0 : "activex"): "") +
                                            " font-Poppins ml-9 sbox7 text-centerx nav-link text-left   caps btn-link "
                        }
                                            id={key + "-list"}
                                            data-bs-toggle="pill"
                                            data-bs-target={"#tab-" + key}
                                            role="tab"
                                            key={key}
                                            aria-controls={"#tab-" + key}
                                            aria-selected="true"
                                            onClick={() => {
                                                setSelectedService(x.value);
                                            }}
                      >
                                            <p className="text-sm"> {x.name}</p>
                                        </button>
                    </li>
                            );
                })}
                        </ul>
                </div>
            </aside>
            <main className="overflow-visible overflow-y-scroll h-3/5x">
                <Suspense fallback={<Loading />}>
                    {renderServiceItem(selectedService)}
                </Suspense>
            </main>
        </div >
      </div >
    </>
  );
}
