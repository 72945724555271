import React from "react";
import { HashLink } from "react-router-hash-link";

export default function MiniMarkerHeader() {
  const fb = "https://www.facebook.com";
  const relativeOrAbsoluteURL1 =
    "https://www.facebook.com/colbornefamilydental1";
  const fbUrl = new URL(relativeOrAbsoluteURL1, fb).href;
  const insta = "https://www.instagram.com/colbornedental/";

  const relativeOrAbsoluteURL2 = "https://www.instagram.com/colbornedental/";
  const instaUrl = new URL(relativeOrAbsoluteURL2, insta).href;
  return (
    <>
      {/* <div className="wsmobileheader clearfix">
      <a href="/" id="wsnavtoggle" className="wsanimated-arrow">
        <span></span>
      </a>
      <span className="smllogo">
        Hello 2
      </span>
      <a href="tel:123456789" className="callusbtn">
        <i className="fas fa-phone"></i>
      </a>
    </div> */}

      <div className="headtoppart bg-black bg-gray-200x clearfix font-semibold">
        <div className="headerwp clearfix">
          {/* Address */}
          <div className="headertopleft text-sm  float-left">
            <div className="d-flex space-x-10 clearfix pt-3">
              <div>
                <HashLink smooth to={"/#location"}>

                  <i className="bi bi-geo-alt text-white"></i>

                  <span className="callusbtn text-white">
                    10 Victoria Square Colborne, ON K0K 1S0
                  </span>
                </HashLink>
              </div>
              <span className="float-right space-x-1">
                <i className="bi bi-telephone text-white"></i>
                <a
                  href="tel:905-355-1419"
                  className="address text-white callusbtn font-weight-bold"
                >
                  905-355-1419
                </a>
              </span>
            </div>
          </div>

          {/* Social Links */}
          <div className="justify-end text-right space-x-2  text-white text-sm">
            <div className="pt-2">
              <a className="text-center space-x-1 p-2 text-white" href="mailto:colbornefamilydental@gmail.com"> <i className="bi bi-envelope text-white"> </i> <span>colbornefamilydental@gmail.com</span></a>

              <ul className="foo-socials text-center mt-2">
                <li>
                  <a href={fbUrl} aria-label="Facebook" rel="noopener noreferrer" target="_blank" className="ico-facebook">
                    <i className="bi bi-facebook text-white"></i>
                  </a>
                </li>
                <li>
                  <a href={instaUrl} aria-label="Instagram" rel="noopener noreferrer" target="_blank" className="ico-instagram">
                    <i className="bi bi-instagram text-white"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="headertopright text-white text-sm justify-end float-right  space-x-3 p-2">

            <a className="text-center" href="mailto:info@ColborneFamilyDental.cs">  <span> colbornefamilydental@gmail.com</span></a>

            <ul className="foo-socials text-center clearfix">
              <li>
                <a href={fbUrl} className="ico-facebook">
                  <i className="bi bi-facebook text-white"></i>
                </a>
              </li>
              <li>
                <a href={instaUrl} className="ico-instagram">
                  <i className="bi bi-instagram text-white"></i>
                </a>
              </li>
            </ul>

          </div> */}
        </div>
      </div>
    </>
  );
}
